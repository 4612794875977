import { useEffect } from "react";
import { withAppContext } from "src/store/initAppContext";
import styled, { withTheme } from "styled-components";
import Accordion from '../../components/Accordion/Accordion';
import SEOContents from "src/components/SEOContents";
import { Helmet } from "react-helmet";
import { theme } from "styled-tools";
import { appQuery47, bonusAndPromotion47, casinoGames47, deposit47, myAccountData47, security47, sportsBetting47, withDrawal47 } from "./Fun88FAQ47";


const structuredData = (domain) => {
    const data = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement":
            [
                {
                    "@type": "ListItem",
                    "item": `${domain}`,
                    "name": "Homepage",
                    "position": 1
                },
                {
                    "@type": "ListItem",
                    "item": `${domain}/faq/`,
                    "name": "FAQ's",
                    "position": 2
                },
            ],
        "name": "Breadcrumbs"
    }

    return JSON.stringify(data)
}

function FAQ47(props: any) {
    const { app: { brandId }, theme } = props
    let WebsiteName = ''
    let WebsiteLink = ''
    switch (props.app.brandId) {
        case 46:
            WebsiteName = 'FUN88';
            WebsiteLink = 'www.fun88.site'
            break;
        case 47:
            WebsiteName = 'FUN88';
            WebsiteLink = 'www.fun88.online'
            break;
    }

    let domainURL = 'https://www.fun88.site';

    if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
    } 
    else if (brandId === 47) {
        domainURL = 'https://www.fun88.online';
    }

    const { config: { showAffiliate } } = theme;

    useEffect(() => {
        window.scrollTo(0, 0)
    })

    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }
                </script>
            </Helmet>
            <BgWrapper>
                <ContentWrapper>
                    <SEOContents pageName="faq" />
                    <AccordionHead className="headBorderRadius">My Account</AccordionHead>
                    {myAccountData47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>Withdrawal</AccordionHead>
                    {withDrawal47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>Deposit</AccordionHead>
                    {deposit47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>Bonuses & Promotions</AccordionHead>
                    {bonusAndPromotion47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>App Related Queries</AccordionHead>
                    {appQuery47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>Casino Games</AccordionHead>
                    {casinoGames47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>Sports Betting</AccordionHead>
                    {sportsBetting47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}

                    <AccordionHead>Security</AccordionHead>
                    {security47.map((faq) => (
                        <Accordion
                            key={faq.id}
                            title={faq?.question}
                            content={() => ({ __html: faq?.answer })}
                            lists={faq?.lists}
                        // redirect={faq?.redirect}
                        />
                    ))}
                </ContentWrapper>
            </BgWrapper>
        </>
    );
}
export default withTheme(withAppContext(FAQ47));

const BgWrapper = styled.div`
    width: 100%;
    background: #EBEBEB;
    padding-top: 1rem;
`;

const AccordionHead = styled.h1`
    font-size: 15px;
    margin-top:10px;
    margin: 1px 0px;
    padding: 15px 15px!important;
    background: #39454b!important;
    color: #fff!important;

    &.headBorderRadius {
        border-top-left-radius: ${theme('palette.FAQAccordionHeadBorderRadius')};  
        border-top-right-radius: ${theme('palette.FAQAccordionHeadBorderRadius')};     
    }
`;

const ContentWrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	font-size: 12px;
	padding: 1em 1.25em;
    background: #fff;
    border-radius: 1rem;

    p,
	h3,
	h4,
	h5,
	h6 {
		color: #000!important;
	}

	h2 {
		font-size: 16px;
	}

	h3 {
		font-size: 14px;
	}

	ul ul,
	ol {
		margin: 1em 0;
	}

	li {
        color: #000;
	}

	a {
		color: #06A6FF;
		text-decoration: underline;
	}

    span{
        font-weight: 700;
    }
`;
