import React, { useEffect } from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { LazyLoadImage } from "react-lazy-load-image-component";
import config from "src/config/config";
import PlaceholderImage from 'src/components/Placeholder';
import { theme } from 'styled-tools';
import FooterContainer from 'src/components/footer/footer';

function AviatrixJetToCashPromo(props) {
    const { app: {
        brandId
    }, theme } = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const domain = (brandId == 46 || brandId == 47) ? "Fun88" : 'Website';

    return (
        <>
            <InformationWrapper>
                <MaxWrapper>
                    <TitleText>
                        <h1>Take Flight with Aviatrix: Jet to Cash, Make a Splash!</h1>
                        <h6>Get ready to soar and win big! Don't miss your chance to grab a share of the massive <span className='customeColor'>₹70 Lacs </span> prize pool in our exciting new tournament. Join now and let your NFT aircraft take you to new heights!</h6>
                    </TitleText>
                    <ROWrapper>
                        <ROLeftWrapper>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayGirlOne}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </ROLeftWrapper>
                        <RoContent>
                            <h1>How to Participate:</h1>
                            <h3>To compete in the daily tournaments, simply follow these two steps:</h3>
                            <TextWrapper>
                                <span>Stage&nbsp;1:</span>
                                <p>Place at least one bet using your NFT aircraft within a 24-hour period.</p>
                            </TextWrapper>
                            <TextWrapper>
                                <span>Stage&nbsp;2:</span>
                                <p>Claim your reward if you've earned one. When your aircraft wins the daily tournament, your reward will be based on its overall game experience. The more experience your aircraft has, the bigger the reward!</p>
                            </TextWrapper>
                        </RoContent>
                    </ROWrapper>
                    <UnderLine></UnderLine>
                    <DetailsWrapper>
                        <HeadingText>
                            <h1>Key Details of the Competition</h1>
                        </HeadingText>
                        <DetailsBodyWrapper>
                            <EachWrapper>
                                <Divider></Divider>
                                <EachBody>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgOne}`}
                                        alt="jetxPromoStepThree"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                    />
                                    <p><span className='customeColor'>Duration:</span> <br />December 1, 2024 <br />to <br />February 28, 2025</p>
                                </EachBody>
                            </EachWrapper>
                            <EachWrapper>
                                <Divider></Divider>
                                <EachBody className='imgTwo'>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgTwo}`}
                                        alt="jetxPromoStepThree"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                    />
                                    <p><span className='customeColor'>Daily Prize Payouts:</span> <br />December 1, 2024 <br />to <br />February 28, 2025</p>
                                </EachBody>
                            </EachWrapper>
                            <EachWrapper>
                                <Divider></Divider>
                                <EachBody>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgThree}`}
                                        alt="jetxPromoStepThree"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                    />
                                    <p>Total Prize Pool: <br /><span className='customeColor fontLarge'>₹70 Lacs</span></p>
                                </EachBody>
                            </EachWrapper>
                            <EachWrapper>
                                <Divider></Divider>
                                <EachBody>
                                    <LazyLoadImage
                                        src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayDetImgFour}`}
                                        alt="jetxPromoStepThree"
                                        effect="none"
                                        placeholder={<PlaceholderImage />}
                                    />
                                    <p>Reward Distribution Time: <br /><span className='customeColor'>12:30 AM IST (19:00 UTC)</span></p>
                                </EachBody>
                            </EachWrapper>
                        </DetailsBodyWrapper>
                    </DetailsWrapper>
                    <BodyWrapper>
                        <LeftWrapper>
                            <RewardsWrapper>
                                <h1>Winning and Rewards:</h1>
                                <ul>
                                    <li><p>Winners and cash rewards are determined daily at 19:00 UTC/ 12:30am IST, with the process split into two stages.</p></li>
                                    <li><p>The higher your game turnover, the better your chances of winning.</p></li>
                                    <li><p>During each competition period, every aircraft's minimum turnover (bet) equals one chance (ticket). The chances for other participating aircraft are proportional to this minimum turnover.</p></li>
                                    <li><p>Once you advance to the second stage, you'll receive your winnings based on your overall gaming experience (total bets). The more experience your aircraft has, the bigger the cash reward.</p></li>
                                    <li><p>To claim your cash prize, simply click the "Claim Prize" button, and your winnings will be credited to your account. These cash prizes have no wagering requirements and can be used for gameplay or withdrawn.</p></li>
                                </ul>
                            </RewardsWrapper>
                        </LeftWrapper>
                        <RightWrapper>
                            <LazyLoadImage
                                src={`${config.imageV3Host}/static/cashsite/${theme.image.AviatrixFlyAndPlayGirlTwo}`}
                                alt="jetxPromoStepThree"
                                effect="none"
                                placeholder={<PlaceholderImage />}
                            />
                        </RightWrapper>
                    </BodyWrapper>
                    <AviatrixTermsAndCondition>
                        <h4>Terms & Conditions</h4>
                        <ul>
                            <li>Each bet earns points equal to the bet amount.</li>
                            <li>Points are accumulated and displayed on the leaderboard. The leaderboard is updated in real-time within the Aviatrix game interface.</li>
                            <li>If multiple participants have the same points, the player who earned their points first will rank higher.</li>
                            <li>Each user can participate using only one game account but may use an unlimited number of NFT aircraft.</li>
                            <li>Winners will be selected daily at 12:30 AM IST (19:00 UTC), and rewards will be distributed in two stages.</li>
                            <li>The higher the game turnover, the better the chances of winning.</li>
                            <li>Each aircraft must meet a minimum turnover (bet amount) to qualify. Chances for other participating aircraft are proportional to their turnover.</li>
                            <li>In the second stage, all participants who qualify will receive winnings. The reward amount depends on the overall gaming experience (total bets). Aircraft with higher gaming experience earn bigger rewards.</li>
                            <li>Players must click the "Claim Prize" button to receive their cash prize, which will then be credited to their account.</li>
                            <li>Cash prizes are non-wagerable and can be used directly for gameplay or withdrawal.</li>
                            <li>Void, canceled, or draw bets, as well as cashed-out bets, do not count towards deposit or bonus rollover requirements.</li>
                            <li>Multiple accounts will not be eligible for this promotion. All players will undergo duplicate checking. FUN88 reserves the right to disqualify players involved in collusion or any foul play related to this promotion.</li>
                            <li>FUN88 & Aviatrix reserves the right to amend, cancel, reclaim, or refuse any promotion at its discretion.</li>
                            <li>General Terms & Conditions of FUN88 apply.</li>
                        </ul>
                    </AviatrixTermsAndCondition>
                </MaxWrapper>
            </InformationWrapper>
            <FooterContainer />
        </>
    )
}



export default withTheme(withAppContext(AviatrixJetToCashPromo));

const MaxWrapper = styled.div`
    width: 100%;
    max-width: 1500px;
    margin: 0 auto; 
`;

const InformationWrapper = styled.div`
    height: auto;
    padding: 3rem;
    border-top-color: transparent;
    display: flex;
    flex-direction: column;
    background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayBackground")});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    .customeColor {
        color: ${theme('palette.AviatrixFlyandPlayContentTextColor')};
    }
`;

const TitleText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem 0 4rem 0;

    h1 {
        background: ${theme('palette.AviatrixFlyandPlayTextColor')};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 29.68px;
        margin: 0;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        text-transform: capitalize;
        font-family: 'Inter-Bold';
    }

    h6 {
        margin: 0;
        padding: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 160.3%;
        text-transform: capitalize;
        text-align: center;
        width: 90%;
    }

    .pinkBold {
        color: #FF9FFF;
        font-weight: 700;
    }

    span {
        color: #DE6C54;
        font-weight: 700;
    }
`;

const BodyWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: 2rem;
    padding-top: 7rem;
    position: relative;
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-bottom: 2rem;
    position: relative;

    :after {
        content: "";
        width: 66px;
        height: 76px;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayBetandWinImgBox")});
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -8%;
        right: 23%;
    }
`;

const RightWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    align-self: flex-end;
    position: absolute;
    right: 9%;

    img {
        width: 35rem;
        margin-bottom: -0.4rem;
    }
`;

const RewardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    border-radius: 20px;
    background: ${theme('palette.AviatrixStagesbackgrond')};
    border: ${theme('palette.AviatrixStagesborder')};
    padding: 2rem 3rem;
    align-self: center;
    position: relative;
    box-shadow: 2px 10px 24.6px 0px #3981C5;

    h1 {
        color: ${theme('palette.AviatrixFlyandPlayContentTextColor')};
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        margin: 0;
        font-family: 'Inter-Bold';
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 13px;
        margin: 0;
        padding: 0 0 2rem 1rem;
    }

    ul li {
        color: #000;
        font-size: 14.682px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;

        p {
            margin: 0;
        }
    }
`;

const TextWrapper = styled.div`
    display: flex;

    span {
        color: ${theme('palette.AviatrixFlyandPlayContentTextColor')};
        font-weight: 700;
        padding-top: 1.5px;
    }

    p {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150.3%; /* 19.248px */
        text-align: left;
        padding-left: 0.5rem;
        text-transform: capitalize;
    }
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem;
    margin: 0 ;
    gap: 1.5rem;
`;

const DetailsBodyWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: stretch;
    gap: 2rem;
`;

const EachWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 18rem;
    border-radius: 16.777px;
    border: ${theme('palette.AviatrixFlyandPlayTermsBorder')};
    background: ${theme('palette.AviatrixFlyandPlayItemsBackground')};
    box-shadow: 2px 10px 24.6px #3981C5;
`;

const EachBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 1.5rem 1.5rem 1.5rem;
    gap: 1rem;
    position: relative;

    &.imgTwo {

        img {
            top: auto;
            left: auto;
        }
    }

    img {
        position: absolute;
        width: 15rem;
        top: -10%;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }

    p {
        margin: 0;
        padding-top: 9rem;
        color: #000;
        text-align: center;
        font-size: 15.682px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 23.523px */
        text-transform: capitalize;
    }

    .fontLarge {
        font-size: 25px;
        padding-top: 10px;
        display: block;
    }
`;

const Divider = styled.div`
    height: 4px;
    width: 60%;
    border-radius: 76px;
    background: ${theme('palette.AviatrixFlyandPlayDividerBg')};
    box-shadow: ${theme('palette.AviatrixFlyandPlayDividerBoxShadow')};
`;

const HeadingText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        margin: 0;
        color: ${theme('palette.AviatrixFlyandPlayContentTextColor')};
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        font-family: 'Inter-Bold';
    }
`;

const ROWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
    gap: 10rem;
    width: 100%;
`;

const ROLeftWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;

    img {
        width: 35rem;
        text-align: center;
        margin-bottom: -0.3rem;
    }
`;

const RoContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 40%;
    padding-right: 2rem;
    border-radius: 20px;
    background: ${theme('palette.AviatrixStagesbackgrond')};
    border: ${theme('palette.AviatrixStagesborder')};
    padding: 3rem;
    align-self: center;
    position: relative;
    box-shadow: 2px 10px 24.6px 0px #3981C5;


    h1 {
        margin: 0;
        color: ${theme('palette.AviatrixFlyandPlayContentTextColor')};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 43.08px */
        text-transform: capitalize;
        text-align: left;
        font-family: 'Inter-Bold';
    }

    h3 {
        margin: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        text-align: left;
        font-weight: 400;
        line-height: 150%; /* 24px */
        text-transform: capitalize;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgFive")});
        width: 17rem;
        height: 16rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -5rem;
        right: -6rem;
    }
`;

const UnderLine = styled.div`
    height: 2px;
    width: 100%;
    margin-top: 2rem;
    background: radial-gradient(1134.2% 50% at 50% 50%, #FFF 0%, rgba(153, 153, 153, 0.00) 100%);
`;

const AviatrixTermsAndCondition = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 20px;
    background: ${theme('palette.AviatrixStagesbackgrond')};
    border: ${theme('palette.AviatrixStagesborder')};
    padding: 2rem 3rem;
    padding-left: 5.5rem;
    align-self: center;
    position: relative;
    box-shadow: 2px 10px 24.6px 0px #3981C5;


    & li {
        color: #000;
        font-size: 14.682px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px; /* 142.5% */
        text-transform: capitalize;
        margin: 5px 0;
    }

    & h4 {
        color: ${theme('palette.AviatrixFlyandPlayContentTextColor')};
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 179.5%; /* 53.85px */
        text-transform: capitalize;
        margin: 10px 0px 0px 15px;
    }

    .bold {
        color: #FFF;
        font-weight: 700;
    }

    ::before {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSix")});
        width: 10rem;
        height: 8rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 5rem;
        right: 5rem;
    }

    ::after {
        content: '';
        position: absolute;
        background: url(${config.imageV3Host}/static/cashsite/${theme("image.AviatrixFlyAndPlayDetImgSeveen")});
        width: 6rem;
        height: 5rem;
        background-position: top;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 17rem;
        left: 1rem;
    }
`;