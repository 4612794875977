import React from 'react'
import { withAppContext } from 'src/store/initAppContext';
import styled, { withTheme } from 'styled-components';
import { theme } from 'styled-tools';
import SeoContentHomePage39 from './SeoContentHomePage39';
import SeoContentHomePage47 from './SeoContentHomePage47';
import SeoContentHomePage46 from './SeoContentHomePage46';

function SeoContentHomePage(props) {
    const { app: {
        brandId
    }, theme: { config } } = props;
    const { showSeoFooterInsidePages } = config

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    } else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }
    // const domainName = (brandId === 31 || brandId === 32) ? '{domainName}' : 'Betdaily';
    const domainName = brandId === 31 || brandId === 32 ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7' : (brandId === 46 || brandId === 47) ? 'Fun88' : 'Betdaily';

    return (
        <>
            {(brandId == 39 || brandId == 40) ? <SeoContentHomePage39 /> : brandId == 33 ? <></> : (
                <AccordionWrapper>
                    <Accordion>
                        <input type="checkbox" id="section1" className="accordion__input" />
                        {/* <label htmlFor="section1" className="accordion__label"><h1>{h1Txt}</h1></label> */}
                        {/* <label htmlFor="section1" className="accordion__label"><h1>Online Betting in India</h1></label> */}
                        <label htmlFor="section1" className="accordion__label"><h1>{brandId == 32 ? "Online Betting ID in India" : brandId === 31 ? "Yolo247 - Best Real Money Online Gambling Platform in India" : (brandId == 39 || brandId == 40) ? "Best Site for Online Betting in India | Explore Sports & Casino" : "Online Betting in India"}</h1></label>
                        {(brandId == 39 || brandId == 40) ? <SeoContentHomePage39 /> : (
                            <div className="accordion__content">
                                {/* <h2>{domainName} — Your Online Betting Partner</h2> */}
                                <h2>{domainName} - Best Real Money Online Gambling Platform in India</h2>
                                <p>
                                    Betting has never been as fun and rewarding as before, the way you are going to experience it on the {domainName} platform. We, the best <strong>real money online gambling</strong> platform, provide a top-class casino and sports betting experience.
                                </p>
                                <p>
                                    Our ever-growing happy users play more than 700 exciting and rewarding casino games while also betting on multiple sports. Get ready to dive into the world of casino and sports betting with us to get amazing experiences and rewards.
                                </p>
                                <h2>{domainName} - Your Ultimate Online Betting Partner</h2>
                                <p>
                                    {domainName} is the best real money online gambling platform, offering exciting casino games and multiple sports betting options. Among all the best Indian betting sites, {domainName} stands out for its online gambling real money features.
                                </p>
                                <p>
                                    We, the best gambling site in India, offer casino real money games in India under the following categories:
                                </p>
                                <ul>
                                    <li>Card games</li>
                                    <li>Wheel games</li>
                                    <li>Dice games</li>
                                    <li>Crash games</li>
                                    <li>Slot games</li>
                                </ul>

                                <p>
                                    Under each category, there are multiple games that you can try and win real cash. Among all <strong>trusted Indian betting sites,</strong> {domainName} has an edge, as it offers some of the most popular sports for betting.
                                </p>

                                <p>
                                    <strong>{domainName} betting online</strong> platform is also quite popular because of instant withdrawals and multiple deposit options. With {domainName} instant withdrawals, you can get your winnings within half an hour in your account.
                                </p>

                                <p>
                                    <strong>CTA:</strong> Download Now
                                </p>

                                <h2>Why is {domainName} Your Best Choice?</h2>

                                <p>
                                    {domainName} is the best <strong>real money online gambling</strong> site for many reasons, and the following are some of the reasons why you should pick {domainName} as your online casino and sports betting partner:
                                </p>

                                <h2>Yolo247 - Your Ultimate Online Betting Partner</h2>
                                <p>Yolo247 is the best <b>real money online gambling</b> platform, offering exciting casino games and multiple sports betting options. Among all the <b>best Indian betting sites</b>, Yolo247 stands out for its online gambling real money features.</p>
                                <p>We, the best gambling site in India, offer casino <b>real money games in India</b> under the following categories:</p>
                                <ul>
                                    <li>
                                        <h3>Simple & Easy Login</h3>
                                        <p>
                                            The magic of online gaming and sports betting lies in their simplicity, and our straightforward navigation makes the gaming experience memorable. Our website and app are dedicated to understanding player's needs and providing them with a rewarding casino and sports betting experience.
                                        </p>
                                        <p>
                                            The easy and smooth login lets the users play their favourite games in no time. All you need to do is enter your credentials and click on 'Login' to get to your favourite casino games and sports betting options.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Wide Range of Casino Games & Sports Betting Options</h3>
                                        <p>
                                            With over 700 casino games in different categories, {domainName} provides immense joy to online casino enthusiasts. There are crash games, <a href={`${domainURL}/online-casino-games/slotGames`}>slot games</a>, card games, dice, and wheel games. Players are free to choose the games of their choice, play them, and win rewards.
                                        </p>
                                        <p>
                                            Apart from many engaging casino games, we also have multiple sports for betting enthusiasts. You can bet on global and domestic sporting events across cricket, football, tennis, hockey, basketball, kabaddi, and more.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Rewarding</h3>
                                        <p>
                                            We offer amazing promotions and bonuses to our players. Keep an eye on a <a href={`${domainURL}/promotions/welcome-bonus`}>welcome bonus</a>, a <a href={`${domainURL}/promotions/refill-bonus`}>refill bonus</a>, and a referral bonus. These bonuses are for both casino games and sports betting.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Safe & Secure</h3>
                                        <p>
                                            We provide the safest platform for  <a href={`${domainURL}/online-casino-games`}>online casino games</a> and sports betting. With our safe and secure payment options, each transaction will be a success without any compromise on security. Payment options like credit/debit cards, UPI, internet banking, e-wallets, etc. are available. Begin your real money gambling journey.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>24/7 Customer Support</h3>
                                        <p>
                                            If you have any queries, our 24/7 customer support team is always ready to assist you. We prioritise your gaming and betting needs and are always ready to help you.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Super Quick</h3>
                                        <p>
                                            Among all the <strong>best betting sites in India with instant withdrawal</strong>, {domainName} is the quickest. Not only our withdrawals, but also our deposits, are faster. If you want to have a seamless online betting experience, then you must join {domainName}.
                                        </p>
                                    </li>
                                    <li>
                                        <h3>Goes the Extra Mile</h3>
                                        <p>
                                            We always go the extra mile for our players with regular promotions and solid bonuses
                                        </p>
                                    </li>
                                </ul>

                                <h2>Low Deposit Betting Site</h2>

                                <p>If you want to know real ways to make money from home for free, then get on the best real money online gambling site, {domainName}. {domainName} is one of the betting sites with welcome bonus where you can deposit and get started with your favourite casino games and sports betting options.</p>

                                <p>{domainName} is also among the minimum deposit betting sites, where you get 400% on your first deposit and a 7% refill bonus on every deposit except the first one.</p>

                                <h2>Games & Sports</h2>

                                <p>{domainName}, the best real money online gambling website, offers a wide range of casino games and sports betting options. In the following sections, you will learn about different categories of casino games, casino game types, and sports betting options.</p>

                                <h3>Casino Games</h3>

                                <p>There are plenty of casino games available to play on the {domainName} platform. You can try different real money casino games on our platform and win handsome rewards.</p>

                                <h4>Category of Casino Games</h4>

                                <p>The following are the categories of casino games available on the {domainName} platform:</p>

                                <h5>Instant Win Games</h5>

                                <p>Instant casino games are fast-paced games without a live dealer. These games are quick and highly rewarding, making them one of the most preferred games in online casinos.</p>

                                <h5>Live Casino</h5>

                                <p>In live casino games, you will have a live dealer who will moderate the game. This category has all the top real money games that offer amazing winnings.</p>

                                <h5>Slots and Bingo</h5>

                                <p>If you want to enjoy slot games for real money, then {domainName} is the perfect place for you. There are multiple varieties of slot games that we offer. Right from fruits and cakes to numbers, we have it all.</p>

                                <h5>Popular Games</h5>

                                <p>Here you will find games that our players like the most. We add games to this section based on how many players play a particular game. You can find the most popular games and start playing them.</p>

                                <h5>Hot Games</h5>

                                <p>These games are the ones that have a higher potential to win and are also easy to understand. In this section, you will also find newly launched games. Try hot games and win exciting rewards.</p>

                                <h4>Casino Game Types</h4>

                                <p>The following are some of the casino game types that are available on our platform:</p>

                                <h5>Card Games</h5>

                                <p>Enjoy the best card games on {domainName}.</p>
                                <ul>
                                    <li><a href={`${domainURL}/online-casino-games/live-baccarat`}>Baccarat</a></li>
                                    <li><a href={`${domainURL}/online-casino-games/teenpatti`}>Teen Patti</a></li>
                                    <li><a href={`${domainURL}/online-casino-games/live-blackjack`}>Live Blackjack</a></li>
                                    <li><a href={`${domainURL}/online-casino-games/dragon-tiger`}>Dragon Tiger</a></li>
                                    <li><a href={`${domainURL}/online-casino-games/andar-bahar`}>Andar Bahar</a></li>
                                    <li><a href={`${domainURL}/instantGames/hi-lo`}>Andar Bahar</a></li>
                                    <li><a href={`${domainURL}/online-casino-games/poker`}>Poker</a></li>
                                </ul>

                                <h5>Wheel Games</h5>

                                <p>Experience the magic of wheel games on {domainName}.</p>
                                <ul>
                                    <li>Lightning Roulette online</li>
                                    <li>XXXtreme Lightning Roulette</li>
                                    <li>Dream Catcher</li>
                                    <li><a href={`${domainURL}/online-casino-games/crazy-time`}>Crazy Time</a></li>
                                    <li>Funky Time</li>
                                    <li>Monopoly</li>
                                    <li>Standard Roulette</li>
                                </ul>

                                <h5>Dice Games</h5>

                                <p>Get rolling with exciting dice games on {domainName}.</p>
                                <ul>
                                    <li>Sic Bo</li>
                                    <li><a href={`${domainURL}/online-casino-games/lightning-dice`}>Lightning Dice</a></li>
                                    <li>Dice</li>
                                    <li>Street Dice</li>
                                    <li>Super Sic Bo</li>
                                    <li>Red Blue Dual</li>
                                    <li>Bonus Dice</li>
                                </ul>

                                <h5>Crash Games</h5>

                                <p>Get high on casino games with our crash games.</p>
                                <ul>
                                    <li><a href={`${domainURL}/instant-games/aviator`}>Aviator online</a></li>
                                    <li><a href={`${domainURL}/instant-games/jetx`}>JetX</a></li>
                                    <li><a href={`${domainURL}/instant-games/aviatrix`}>Aviatrix</a></li>
                                    <li>Cash Show</li>
                                    <li>Rocketon</li>
                                    <li>Crash</li>
                                    <li>JetX3</li>
                                </ul>

                                <h5>Slot Games</h5>

                                <p>Get an immersive online casino experience with our slot games.</p>
                                <ul>
                                    <li>Starburst</li>
                                    <li>Fruit Shop</li>
                                    <li>Golden 777</li>
                                    <li>Rome: The Golden Age</li>
                                    <li>Blood Suckers</li>
                                    <li>Gordon Ramsay's Hell's Kitchen</li>
                                    <li>Dracula</li>
                                </ul>

                                <p><a href={`${domainURL}/online-casino-games`}>Play Now</a></p>

                                <h3>Sports Betting Options</h3>

                                <p>To experience the magic of sports betting and to win massive rewards, you need to get on the best real money online gambling site, {domainName}. The variety of sports, competitive odds, live betting experience with real money, and different sports betting categories make {domainName} the best real money online gambling site.</p>

                                <h4>Competitive Odds</h4>

                                <p>When it comes to sports betting in India, people look for the best betting odds website. We understand bettors’ needs and offer competitive odds and unique betting markets. We ensure that you get an amazing sports betting experience and the best possible returns on your bets.</p>

                                <h4>Live Betting Experience with Real Money</h4>

                                <p>The craze for online sports betting in India is growing massively, and one of the main reasons is live betting with real money. Many online betting sites in India provide the live betting experience, but only {domainName} provides it with competitive odds and also covers plenty of sporting events. In live betting, you can place bets in real-time and maximise your winnings.</p>

                                <h4>{domainName} Online Sports Betting Categories</h4>

                                <p>The following are the 4 online sports betting categories on {domainName}:</p>
                                <ul>
                                    <li><a href={`${domainURL}/betting-exchange`}>Betting Exchange</a></li>
                                    <li><a href={`${domainURL}/sportsbook`}>Sportsbook</a></li>
                                    <li><a href={`${domainURL}/premium-sportsbook`}>Premium Sportsbook</a></li>
                                    <li>Virtual Sports Betting</li>
                                </ul>

                                <p>All 4 options are available across sports like cricket, football, tennis, and more.</p>

                                <p><a href="#">Place Bets Now</a></p>

                                <h4>Top Sports to Bet on {domainName}</h4>

                                <p>When it comes to the best cricket betting sites in India, {domainName} leads the way. We also offer other sports on which you can bet and win handsome rewards. The following are the top sports that we offer:</p>
                                <ul>
                                    <li><a href={`${domainURL}/betting-exchange/online-cricket-betting`}>Cricket</a></li>
                                    <li><a href={`${domainURL}/betting-exchange/football-betting`}>Football</a></li>
                                    <li>Basketball</li>
                                    <li>Kabaddi</li>
                                    <li>Volleyball</li>
                                    <li><a href={`${domainURL}/betting-exchange/tennis-betting`}>Tennis</a></li>
                                    <li>Badminton</li>
                                </ul>

                                <h4>Top Tournaments to Bet On {domainName}</h4>

                                <p>Online betting in India is on the rise, and one of the main reasons is the popular tournaments that capture the attention of sports and betting enthusiasts. The following are the top sports that are available to bet on the best real money online gambling site:</p>
                                <ul>
                                    <li>IPL (Indian Premier League)</li>
                                    <li>BBL (Big Bash League)</li>
                                    <li>CPL (Caribbean Premier League)</li>
                                    <li>PSL (Pakistan Super League)</li>
                                    <li>T20 World Cup (T20 WC)</li>
                                    <li>World Cup (ODI)</li>
                                    <li>EPL (English Premier League)</li>
                                    <li>FIFA World Cup (FIFA WC)</li>
                                </ul>

                                <p>Apart from the tournaments mentioned above, there are 4 grand slams a year, different football leagues around the world, the Olympics, and many more.</p>

                                <h4>Popular Bet Types on {domainName}</h4>

                                <p>The following are the popular bet types on the best real money online gambling site in India:</p>
                                <ul>
                                    <li>Straight Bet</li>
                                    <li>Parlay Bet</li>
                                    <li>Over/Under Bet</li>
                                    <li>Future Bet</li>
                                    <li>Prop Bet</li>
                                    <li>Spread Bet</li>
                                    <li>Multi Bet</li>
                                </ul>

                                <h2>How to Get Started on {domainName}</h2>

                                <p>To begin your casino and sports betting journey on the best real money online gambling site in India, you will need to follow some simple and quick steps, which are as follows:</p>

                                <h3>Create Your Account</h3>

                                <p>To create your {domainName} account, follow some easy steps:</p>
                                <ol>
                                    <li>Click on the <a href={`${domainURL}/signup`}>'Signup'</a>  button present at the top right corner.</li>
                                    <li>Create username, password, and enter your mobile number.</li>
                                    <li>Submit the OTP, and fill in the remaining details to become a {domainName} user.</li>
                                </ol>

                                <h3>Deposit Funds</h3>

                                <p>Once you are a registered user, you will need to make your first deposit to get a 400% welcome bonus. You can use the deposited amount to play your favourite casino games and do sports betting. To deposit amount on {domainName}, follow these steps:</p>
                                <ol>
                                    <li>Log in to your Yolo</li>
                                    <li>Click on the deposit button.</li>
                                    <li>Choose any 1 of the 4 options: Fast Bank, Fast UPI, Regular UPI, or Net Banking.</li>
                                    <li>Enter deposit amount, UTR number, and upload the payment receipt.</li>
                                </ol>

                                <h3>Place your Bets</h3>

                                <p>Now that you have deposited some amount, you can bet on various casino games and sports. To bet on a casino game, you will need to pick your favourite game from 'popular games', 'live casino', 'slot & bingo', <a href={`${domainURL}/instantGames`}>'instant games'</a>, or 'hot games'. Then place bets as per the game rules and within the given time.</p>

                                <p>To place bets on sports, click on the 'sports' section. Select 'exchange', 'premium sportsbook', or 'sportsbook'. Pick any present match or an upcoming one, and place your bets.</p>

                                <h2>Bet Anywhere with the {domainName} App</h2>

                                <p>To experience online casino and sports betting at their best, you will need to get on the best real money online gambling app in India, {domainName}. For the {domainName} casino mobile app and sports betting app download, follow some simple and quick steps:</p>
                                <ol>
                                    <li>Scan the QR code that is present on the top right corner of the homepage.</li>
                                    <li>Download the <a href={`${domainURL}/app-download`}>{domainName} app</a>  and follow the simple instructions to install the app.</li>
                                </ol>

                                <p>Once the <a href={`${domainURL}/app-download`}>{domainName} app</a>  is installed, get yourself registered. Enter your credentials to log in and deposit some amount to get started with your favourite casino games and sports betting options.</p>

                                <h2>Exclusive Offers and Bonuses with {domainName}</h2>

                                <p>If you want amazing bonuses and exciting promotions, then you need to get on the best real money online gambling site in India, {domainName}. {domainName} is the best platform among all the betting sites with free bonus.</p>

                                <h3>{domainName} Bonus</h3>

                                <p>The following are some of the incredible bonuses that are present on our platform:</p>

                                <h4>Welcome Bonus</h4>

                                <p>We value our players and welcome them with open arms. Every new user gets a massive 400% welcome bonus on their first deposit.</p>

                                <h4>Refill Bonus</h4>

                                <p>We like our players enjoying different casino games and sports betting options. To keep the fun going for our players, we offer a 7% refill bonus on every deposit except the first one.</p>

                                <h4>Referral Bonus</h4>

                                <p>Not only do we care about our players, but we also value their friends and want them to have the same fun. Players get a 20% referral bonus for referring their friends to the best real money online gambling site.</p>

                                <h3>{domainName} Loyalty Benefits</h3>

                                <p>Loyalty program benefits on {domainName} give benefits of brand loyalty to its players. There are 5 tiers to this program: Blue, Silver, Gold, Platinum, and Elite. Each tier has benefits for players in varying amounts and conditions.</p>

                                <p>Let us have a look at the different tiers of the loyalty program on {domainName}:</p>

                                <h4>Blue</h4>
                                <ul>
                                    <li>2% bonus on every redeposit.</li>
                                    <li>2% cashback bonus on weekly losses.</li>
                                    <li>No minimum turnover amount is required to be in the Blue tier.</li>
                                    <li>No turnover conditions are present. The bonus is directly deposited in your wallet, and there is no maximum limit.</li>
                                </ul>

                                <h4>Silver</h4>
                                <ul>
                                    <li>3% bonus on every redeposit.</li>
                                    <li>3% cashback bonus on weekly losses.</li>
                                    <li>10 lakhs per month turnover amount is required to be in the Silver tier.</li>
                                    <li>No turnover conditions are present. The bonus is directly deposited in your wallet, and there is no maximum limit.</li>
                                </ul>

                                <h4>Gold</h4>
                                <ul>
                                    <li>4% bonus on every redeposit.</li>
                                    <li>4% cashback bonus on weekly losses.</li>
                                    <li>40 lakhs per month turnover amount is required to be in the Gold tier.</li>
                                    <li>No turnover conditions are present. The bonus is directly deposited in your wallet, and there is no maximum limit.</li>
                                </ul>

                                <h4>Platinum</h4>
                                <ul>
                                    <li>5% bonus on every redeposit.</li>
                                    <li>5% cashback bonus on weekly losses.</li>
                                    <li>1 crore per month turnover amount is required to be in the Platinum tier.</li>
                                    <li>No turnover conditions are present. The bonus is directly deposited in your wallet, and there is no maximum limit.</li>
                                </ul>

                                <h4>Elite</h4>
                                <ul>
                                    <li>You can get into this tier by invitation only.</li>
                                    <li>Elite users’ terms will be discussed by their respective relationship managers.</li>
                                </ul>

                                <h2>Secure and Flexible Payment Options</h2>
                                <p>You will get flexible payment options on the best real money online gambling site in India, {domainName}. You can make deposits using credit/debit cards, UPIs, wallets, or through Net Banking. The secure and flexible payment options allow you to make the payment with the option that you are most comfortable with.</p>
                                <p>Deposit with the safe payment options and start enjoying on the best casino and sports betting platform.</p>

                                <h2>{domainName} Educational Resources</h2>
                                <p>To keep your betting and casino game up, you can refer to various guides and strategies that are regularly updated on the {domainName} blog. The best real money online gambling site in India ensures that players always keep learning and improving their game with the different strategies that are explained in a simple way.</p>

                                <h2>Stay Updated with the Latest News</h2>
                                <p>To get the latest news and updates on the online casino and betting industry, read the latest news on the best real money online gambling site in India. Stay updated and be one step ahead of everyone else.</p>

                                <h2>FAQs</h2>
                                <h4>Which is the best real money online gambling site in India?</h4>
                                <p>{domainName}, with its best odds, multiple casino games, and different sports, is the best real money online gambling site in India.</p>

                                <h4>Which are some of the most popular online casino games on {domainName}?</h4>
                                <p>Crazy Time, Lightning Roulette, Aviator, Dragon Tiger, and Teen Patti are some of the popular online casino games on {domainName}.</p>

                                <h4>Which sports are available for betting on {domainName}?</h4>
                                <p>Sports like cricket, football, tennis, basketball, kabaddi, hockey, and more are available for betting on {domainName}.</p>

                            </div>
                        )}
                    </Accordion>
                </AccordionWrapper>
            )}
        </>
    )
}

export default withTheme(withAppContext(SeoContentHomePage))

//Styled Components
export const Accordion = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    color: ${theme('palette.seoContenAccordionHeadTextColor')};
    background: ${theme('palette.seoContenAccordionHeadBg')};

    .accordion__label, .accordion__content {
        padding: 14px 20px;
    }

    h1 {
        display: block;
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }

    h2 {
        display: block;
        font-size: 1.5em;
        font-weight: bold;
        color: ${theme('palette.seoContenAccordionHeadTextColor')};
    }

    h3 {
        display: block;
        font-size: 1.1em;
        font-weight: bold;
    }

    h5 {
        display: block;
        font-size: 0.9em;
        font-weight: bold;
    }

    .number {
        list-style: number;

        li {
            font-weight: bold;
        }
    }

    ul {
        li {
            font-size: 0.91em;
        }
    }

    p {
        margin: 5px 0 10px 0;
        color: ${theme('palette.seoContenAccordionInnerTextColor')};
        font-size: 0.91em;
        font-weight: normal;
    }

    a {
        /* color: #f4a322; */
        color: ${theme('palette.seoContenAccordionInnerAngerTagColor')};
    }

    .linkBg {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-wrap: wrap;
        gap: 5px;

        a {
            background: ${theme('palette.seoContenAccordionLinkBgColor')};
            border-radius: 5px;
            padding: 5px 10px;
        }
    }

    .textTransformNone {
        text-transform: none;
    }

    .accordion__label{
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme('palette.seoContenAccordionHeadTextColor')};
        padding: 20px;
        background: ${theme('palette.seoContenAccordionHeadBg')};
        text-align: center;
        position: relative;
        cursor: pointer;

        :after {
            content: "";
            display: inline-block;
            border-style: solid;
            border-color: ${theme('palette.seoContenAccordionArrowBorder')};
            border-width: 0 2px 2px 0;
            padding: 2.5px;
            vertical-align: middle;
            transition: transform 0.3s;
            /* transform: rotate(225deg); */
            transform: ${theme('palette.seoContenAccordionArrowMarkAfter')};
            position: absolute;
            right: 20px;
        }
    }

    .accordion__label:hover{
        background: ${theme('palette.seoContenAccordionHeadHoverBg')};
    }

    .accordion__content {
        background: ${theme('palette.seoContenAccordionContentBg')};
        line-height: 1.6;
        font-size: 0.85em;
        opacity: ${theme('palette.seoContenAccordionContentOpacityOpen')};
        text-transform: capitalize;
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightOpen')};
        padding: ${theme('palette.seoContenAccordionContentPaddingOpen')};
        margin: ${theme('palette.seoContenAccordionContentMarginOpen')};
        overflow-x: ${theme('palette.seoContenAccordionContentOverFlowXOpen')};
        overflow-y: ${theme('palette.seoContenAccordionContentOverFlowYOpen')};
        transition: 0.3s;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar {
          width: 10px;
          background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background:gray;
          margin-bottom: 10px;
        }
    }

    .accordion__input {
        display: none;
    }

    .accordion__input:checked ~ .accordion__content {
        opacity: ${theme('palette.seoContenAccordionContentOpacityClosed')};
        max-height: ${theme('palette.seoContenAccordionContentMaxHeightClosed')};
        padding: ${theme('palette.seoContenAccordionContentPaddingClosed')};
        margin: ${theme('palette.seoContenAccordionContentMarginClosed')};
    }

    .accordion__input:checked ~ .accordion__label:after {
        transform: ${theme('palette.seoContenAccordionArrowMarkBefore')};
    }

    .td,th{
        border: 1px solid white;
    }
  
    .table, th, td {
  border: 1px solid white;
  border-collapse: collapse;
}

`;

export const AccordionWrapper = styled.div`
  table,th,td{
    border: 1px solid white;
  }
`;