import React, { useState, useEffect } from 'react'
import styled, { withTheme } from 'styled-components';
import { Link } from "react-router-dom";
import Intl from '../common/Intl';
import config from 'src/config/config';
import { submenus } from './headermenu';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PlaceholderImage from 'src/components/Placeholder';
import { withAppContext } from "src/store/initAppContext";
import { theme } from 'styled-tools';
import { CRMHeaderMenuClick } from 'src/util/crmUtil';

type NewHeaderMenuProps = {
    type: string
}

type menuProps = {
    leftMenu: {
        links: any;
        footer: any
    },
    rightMenu: any
}

function NewHeaderMenu(props) {
    let {type =''} = props;

    const { app: {
        brandId
      }, theme } = props;
      
    const themeConfig = theme.config;
    const [submenu, setsubmenu] = useState({} as menuProps);

    useEffect(() => {
        type = (type || '').toLocaleLowerCase();
        if(submenus[brandId]){
            const currentSelection = submenus[brandId][type];
            setsubmenu(currentSelection);
        }
    }, [type]);

    return submenu && submenu ? (
        <DropDownWrapper className={`${type}`}>
            <SubMenuLeft>
                <MenuHeader>
                    {submenu.leftMenu && submenu.leftMenu.links.map(link => {
                        const authkey = link.authkey;
                        const toShow = authkey ? themeConfig[authkey] !== false : true;

                        if (toShow && link.url === '/sports/tournaments/odi-world-cup-betting') {
                            return (
                                <GamePath key={link.id}>
                                    <a href={'https://www.yolo247.site/sports/tournaments/odi-world-cup-betting/'}><Intl langKey={link.label} /></a>
                                </GamePath>
                            );
                        } else if (toShow) {
                            return (
                                <GamePath key={link.id}>
                                    <Link onClick={() => CRMHeaderMenuClick(type, brandId, link.url)} to={link.url}><Intl langKey={link.label} /></Link>
                                </GamePath>
                            );
                        }

                return null;
            })}
                </MenuHeader>

                <MenuFooter>
                    {submenu.leftMenu && submenu.leftMenu.footer.map(link => <p key={link.id}><Intl langKey={link.label} /></p>)}
                </MenuFooter>
            </SubMenuLeft>

            <SubMenuRight>
                <Column>
                    {submenu.rightMenu && submenu.rightMenu.map(menu => {
                      
                        const authkey = menu.authkey;
                        const toShow = authkey ? themeConfig[authkey] !== false : true;

                        return toShow && <Row key={menu.id}>
                            <Link onClick={() => CRMHeaderMenuClick(type, brandId, menu.url)} to={menu.url}>
                                <LazyLoadImage src={`${config.imageV3Host}/static/media/navImage/${menu.image}`} alt="navImage" effect="none" height="100%" width="100%" placeholder={<PlaceholderImage />} />
                                <Tittle><Intl langKey={menu.label} /></Tittle>
                            </Link>
                        </Row>
                    })
                    }
                </Column>
            </SubMenuRight>
        </DropDownWrapper>
    ) : null
}

export default  withTheme(withAppContext(NewHeaderMenu));

//Styled Components
const Column = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const MenuFooter = styled.div`
    width: 100%;
    display: flex;
    /* padding-bottom: 1em; */
    padding-bottom: ${theme('palette.menuFoterWraprPadngBtm')};

    p {
        width: 100%;
        padding: 2rem;
        margin: 0;
        margin-top: 0.4rem;
        /* color: #fff; */
        color: ${theme('palette.menuFooterContentColor')};
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0.05em;
        padding-left: 2rem;
        text-transform: capitalize;
    }
`;

const MenuHeader = styled.div`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2em;
    overflow: auto;
    &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #141313;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background:gray;
    margin-bottom: 10px;
  }

`;

const Tittle = styled.div`
    font-size: 30px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    color: ${theme('palette.gamePathLinkColor')};
    font-family: 'Inter-Bold';

    @media only screen and (max-width: 1600px){
        font-size: 25px;
    }
`;

const Row = styled.div`
    display: flex;
    align-items:center;
    flex-direction: column;
    padding: 1em;
    position: relative;
    padding-bottom: 1em;

    a{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img {
        width: 350px;
        max-height: 346px;
    }
`;

const GamePath = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    :last-child {
        border-bottom: none;
    }

    a {
        width: 100%;
        color: ${theme('palette.gamePathLinkColor')};
        text-transform: uppercase;
        font-size: 14px;
        padding: 20px;
        padding-left: 0;
        border-bottom: ${theme('palette.gamePathLinkDashBorder')};

        :hover {
            color: ${theme('palette.gamePathLinkColorHover')};
            background-color: ${theme('palette.gamePathLinkBgHoverColor')};
            border-top-right-radius: 10px;
            font-weight: ${theme('palette.gamePathLinkHoverFontWeight')};
        }
    }
`;

const SubMenuLeft = styled.div`
    /* width: 316px; */
    width: 23%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    /* background: #fff url(${config.imageV3Host}/static/media/navImage/subMenuLeftBg.png) no-repeat center center; */
    background: #fff url(${config.imageV3Host}/${theme('image.subMenuLeftBgImg')}) no-repeat center center;
    background-size: cover;
    /* overflow: auto; */
`;

const SubMenuRight = styled.div`
    /* flex: 1; */
    width: 77%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const DropDownWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-radius: 5px;

    &.Sports {
        p {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.stroke_sports')}) no-repeat; 
            background-size: contain;
            background-position: center;
            background-color: ${theme('palette.menuFooterContentBgColor')};
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.DROPDOWN_SPORTS_BG')}) no-repeat center center; 
            background-size: cover;
            
        }
    }

    &.hotgames {
        p {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.stroke_teenpatti')}) no-repeat; 
            background-size: contain;
            background-position: center;
            background-color: ${theme('palette.menuFooterContentBgColor')};
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.HOTGAMES_BG_DROPDOWN')}) no-repeat center center; 
            background-size: cover;
        }
    }

    &.casino {
        p {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.stroke_casino')}) no-repeat; 
            background-size: contain;
            background-position: center;
            background-color: ${theme('palette.menuFooterContentBgColor')};
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.DROPDOWN_CASINO_BG')}) no-repeat center center; 
            background-size: cover;
        }
    }

    &.IPL_CUP_WINNER {
        p {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.stroke_casino')}) no-repeat; 
            background-size: contain;
            background-position: center;
            background-color: ${theme('palette.menuFooterContentBgColor')};
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.DROPDOWN_IPL_CUP_WINNER_BG')}) no-repeat center center; 
            background-size: cover;
        }
    }

    /* &.virtual_sports{
        p {
            background: #2e2e2e url(${config.imageV3Host}/static/media/navImage/stroke_iplcup.png) no-repeat;
            background-size: contain;
            background-position: center;
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/static/media/navImage/VIRTUALSPORTS_BG_DROPDOWNN.png) no-repeat center center;
            background-size: cover;
        }
    } */
    

    &.promotions {
        p {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.stroke_promotions')}) no-repeat;
            background-size: contain;
            background-position: center;
            background-color: ${theme('palette.menuFooterContentBgColor')};
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.PROMOTION-DROPDOWN-BG-NEW')}) no-repeat center center;
            background-size: cover;
        }
    }

    &.instant_games {
        p {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.stroke_instant_games')}) no-repeat;
            background-size: contain;
            background-position: center;
            background-color: ${theme('palette.menuFooterContentBgColor')};
        }

        ${Column} {
            background: #2e2e2e url(${config.imageV3Host}/${theme('image.VIRTUALSPORTS_BG_DROPDOWNN')}) no-repeat center center;
            background-size: cover;
        }
    }
`;
